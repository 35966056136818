<template>
  <div class="intro-page">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'Intro',
  mounted() {},
};
</script>
<style scoped lang="scss">
  .intro-page {
    width: 100%;
    max-width: 750px;
    margin: auto;
    height: 100%;
    position: relative;
    background: #fff;
  };
</style>
